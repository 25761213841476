<template>
  <v-app-bar
    id="app-bar"
    flat
    color="white"
    app
    elevation="2"
    class="app-bar"
  >
    <!-- Mobile Menu Button -->
    <v-btn
      class="mr-3"
      v-if="!$vuetify.breakpoint.mdAndUp"
      elevation="1"
      fab
      small
      color="primary"
      @click="setDrawer(!drawer)"
    >
      <v-icon color="white">
        {{ value ? 'mdi-view-grid' : 'mdi-menu' }}
      </v-icon>
    </v-btn>

    <!-- Page Title -->
    <v-toolbar-title
      class="font-weight-medium d-flex align-center"
    >
      <!-- Back Navigation for Sub Pages -->
      <a 
        @click="$router.go(-1)" 
        v-if="$route.meta.sub_page" 
        class="back-link"
      >
        <v-icon small class="mr-1">mdi-arrow-left</v-icon>
        {{ $route.meta.parent }}/
      </a>
      
      <!-- Page Title Display -->
      <template v-if="$route.name !== 'Fulfill'">
        <template v-if="!editing || !$route.params.shelve_index">
          <span class="page-title">
            {{ `${$route.name} ${ ($route.params.shelve_index && shelves[+$route.params.shelve_index - 1].name) || $route.params.shelve_index || ''}` }}
          </span>
          <v-btn
            small
            icon
            class="ml-2"
            @click="set_editing"
            v-if="$route.params.shelve_index !== undefined"
          >
            <v-icon small color="primary">mdi-pencil</v-icon>
          </v-btn>
        </template>
        
        <!-- Shelve Name Editing -->
        <template v-else>
          <v-text-field
            dense
            ref="shelve_name"
            class="mt-2 mb-2 shelve-name-field"
            prefix="Shelve "
            v-model="shelve_name"
            @keyup="on_update_shelve_name"
            :rules="[
              v => !!v || 'Required',
              v => !shelves.some((s, i) => s.name === v || (!s.name && i + 1 === +v)) || 'Name exists'
            ]"
            hide-details
          />
          <v-btn
            v-if="shelves[0].name"
            icon
            x-small
            color="primary"
            class="ml-1"
            @click="on_restore_shelve"
            title="Restore names"
          >
            <v-icon x-small>mdi-restore</v-icon>
          </v-btn>
        </template>
      </template>
      
      <!-- Fulfill Page Restart Button -->
      <v-btn 
        v-if="$route.name === 'Fulfill'"
        outlined
        small
        color="primary"
        class="ml-2"
        @click="refresh_bucket"
      >
        <v-icon small left>mdi-refresh</v-icon>
        Restart
      </v-btn>
    </v-toolbar-title>
    
    <v-spacer />
    
    <!-- Products Syncing Indicator -->
    <div
      v-if="loading_products"
      class="d-flex align-center mr-4"
    >
      <v-progress-circular
        size="20"
        indeterminate
        color="primary"
        class="mr-2"
      />
      <div class="text-body-2">Syncing products</div>
    </div>
    
    <!-- Company Selector -->
    <v-select
      v-if="user.userProfile && user.userProfile.companies.length > 1"
      :items="user.userProfile.companies"
      item-text="name"
      item-value="id"
      dense
      v-model="$store.state.warehouse.current_company_id"
      @change="change_company"
      class="mt-3 company-select"
      hide-details
      outlined
      rounded
    >
      <template v-slot:prepend-inner>
        <v-icon small color="primary">mdi-domain</v-icon>
      </template>
    </v-select>
    
    <!-- Printserver Status -->
    <v-chip
      color="primary"
      v-if="printserver_connected"
      @click="$router.push('/auth/settings/printers')"
      class="ml-3 status-chip"
      small
      outlined
    >
      <v-icon  small>mdi-printer-check</v-icon>
      <span class="ml-1" v-if="$vuetify.breakpoint.mdAndUp">Printserver Connected</span>
    </v-chip>
    <v-chip
      v-else-if="printserver_id"
      @click="$router.push('/auth/settings/printers')"
      color="warning"
      class="ml-3 status-chip"
      small
      outlined
    >
      <v-icon small>mdi-printer-alert</v-icon>
      <span class="ml-1" v-if="$vuetify.breakpoint.mdAndUp">Printserver disconnected</span>
    </v-chip>

    <!-- User Menu -->
    <v-menu
      bottom
      left
      offset-y
      origin="top right"
      transition="scale-transition"
      v-if="is_fh && !($vuetify.breakpoint.mdAndDown && $route.name === 'Fulfill')"
    > 
      <template v-slot:activator="{ attrs, on }">
        <v-btn
          class="ml-3"
          min-width="0"
          icon
          v-bind="attrs"
          v-on="on"
        >
          <v-avatar size="32" color="primary" class="user-avatar">
            <span v-if="userInitials" class="white--text">{{ userInitials }}</span>
            <v-icon class="white--text" v-else>
              mdi-account
            </v-icon>
          </v-avatar>
        </v-btn>
      </template>
      <v-list
        rounded
        elevation="3"
        class="user-menu"
      > 
        <v-list-item
          v-for="(n, i) in user_menu"
          :key="`item-${i}`"
          @click="n.action"
          class="user-menu-item"
        >
          <v-list-item-icon>
            <v-icon>{{ n.icon }}</v-icon>
          </v-list-item-icon>
          <v-list-item-title>{{ n.title }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
    
    <!-- Progress Bar for Fulfill Page -->
    <div class="progress-container" v-if="$route.name === 'Fulfill' && total_remaining_picks">
      <v-scale-transition>
        <v-avatar
          class="progress-badge"
          :style="{'right': `${100 - remaining_percentage}%`}"
          size="20"
          color="primary"
          v-if="show_badge"
        >
          <span class="white--text text-caption">{{ total_remaining_picks }}</span>
        </v-avatar>
      </v-scale-transition>
      <v-progress-linear 
        :value="remaining_percentage"
        color="primary"
        height="4"
      >
      </v-progress-linear>
    </div>
  </v-app-bar>
</template>

<script>
import { mapState, mapMutations } from 'vuex'

export default {
  name: 'DashboardCoreAppBar',

  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },

  data: (e) => ({
    editing: false,
    original_shelve_name: '',
    shelve_name: '',
    user_menu: [
      {
        title: 'Sign out',
        icon: 'mdi-logout',
        action: () => { e.onSignOut() }
      }
    ],
    is_fh: process.env.VUE_APP_MODE === 'fh',
    show_badge: false
  }),

  computed: {
    ...mapState({
      drawer: state => state.app.drawer,
      shelves: state => state.warehouse.shelves,
      user: state => state.user,
      shelf_changed: state => state.warehouse.shelf_changed,
      loading_products: state => state.product.loading_products,
      printserver_connected: state => state.warehouse.printserver_connected,
      printserver_id: state => state.warehouse.company.printserver_id,
      total_remaining_picks: state => state.order.total_remaining_picks,
      starting_amount_picks: state => state.order.starting_amount_picks,
      route: state => state.route.path,
    }),
    remaining_percentage(){
      return (this.total_remaining_picks / this.starting_amount_picks) * 100
    },
    is_dev(){
      return process.env.NODE_ENV === 'development'
    },
    userInitials() {
      if (!this.user.userProfile || !this.user.userProfile.name) return '';
      return this.user.userProfile.name
        .split(' ')
        .map(n => n[0])
        .join('')
        .toUpperCase()
        .substring(0, 2);
    }
  },
  
  watch: {
    remaining_percentage: function(val) {
      if(!val) return
      this.show_badge = true
      setTimeout(() => this.show_badge = false, 3000)
    },
    shelf_changed: function(val) {
      if(!val) return this.editing = false
    },
    route: function() {
      this.editing = false
    },
  },

  methods: {
    developer(){
      this.$store.dispatch('warehouse/developer')
    },
    on_update_shelve_name(){
      if(!this.$refs.shelve_name.valid) return this.shelves[+this.$route.params.shelve_index - 1].name = this.original_shelve_name
      else {
        this.$store.state.warehouse.shelves = this.shelves.map((shelve, i) => ({
          ...shelve,
          name: shelve.name || (i + 1).toString()
        }))
        this.shelves[+this.$route.params.shelve_index - 1].name = this.shelve_name
      }
    },
    async on_restore_shelve(){
      const { ok } = await this.$prompt({
        text: 'This will restore all shelf names to numbers, based on their index and position. Are you sure?',
        buttons: [
          {
            value: { ok: true },
            text: 'Proceed'
          },
          {
            value: { ok: false },
            text: 'Cancel'
          },
        ]
      })
      if(!ok) return
      this.$store.state.warehouse.shelves = this.shelves.map((shelve) => {
        delete shelve.name
        return shelve
      }
      )
      this.$store.commit('warehouse/CHANGE_SHELF', 0)
      this.editing = false
    },
    set_editing(){
      this.original_shelve_name = (this.$route.params.shelve_index && this.shelves[+this.$route.params.shelve_index - 1].name) || this.$route.params.shelve_index
      this.shelve_name = (this.$route.params.shelve_index && this.shelves[+this.$route.params.shelve_index - 1].name) || this.$route.params.shelve_index
      this.editing = true
      this.$store.commit('warehouse/CHANGE_SHELF', 0)
    },
    change_company(id){
      this.$store.dispatch('warehouse/set_company', id)
    },
    ...mapMutations({
      setDrawer: 'app/SET_DRAWER',
    }),
    refresh_bucket(){
      this.$store.dispatch('order/refresh_order_bucket')
    },
    onSignOut(){
      this.$auth.logout()
        .then(() => this.$router.push('/signin'))
        .catch(e => console.log('error', e))
    }
  },
}
</script>

<style lang="scss" scoped>
.app-bar {
  border-bottom: 1px solid rgba(0, 0, 0, 0.05) !important;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05) !important;
}

.page-title {
  font-size: 1.1rem;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.8);
}

.back-link {
  display: flex;
  align-items: center;
  color: rgba(0, 0, 0, 0.6);
  text-decoration: none;
  margin-right: 4px;
  transition: color 0.2s ease;
  
  &:hover {
    color: var(--v-primary-base);
  }
}

.shelve-name-field {
  max-width: 150px;
}

.company-select {
  max-width: 180px;
}

.status-chip {
  font-weight: 500;
  transition: transform 0.2s ease;
  
  &:hover {
    transform: translateY(-2px);
  }
}

.user-avatar {
  cursor: pointer;
  transition: transform 0.2s ease;
  
  &:hover {
    transform: scale(1.1);
  }
}

.user-menu {
  min-width: 180px;
  
  .user-menu-item {
    transition: background-color 0.2s ease;
    
    &:hover {
      background-color: rgba(76, 175, 80, 0.1);
    }
  }
}

.progress-container {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 4px;
}

.progress-badge {
  position: absolute;
  top: -10px;
  z-index: 1;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

@media (max-width: 600px) {
  .page-title {
    font-size: 1rem;
  }
}
</style>
